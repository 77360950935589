.active {
  color: #026aa2 !important;
  background-color: #eaecf0;
  cursor: pointer;
  border-radius: 0.5rem;
}

.inactive {
  color: transparent;
}
.mobileActive {
  color: #026aa2 !important;
  cursor: pointer;
  background-color: #eaecf0;
}
