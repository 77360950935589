body {
  font-family: Inter, sans-serif;
}
.w-100 {
  width: 100% !important;
}

.ids {
  background: rgb(224, 242, 254);
  background: linear-gradient(0deg, rgba(224, 242, 254, 0) 0%, #e0f2fe 100%);
}
