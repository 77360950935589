.caseStudy_Card {
  background-color: #f2f4f7;
  -webkit-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.caseStudy_Content {
  color: #1d2939;
}

.caseStudy_CardPara {
  /* display: none; */
  visibility: hidden;
}

.caseStudy_Card:hover .caseStudy_Content {
  color: white;
}
.caseStudy_Card:hover .caseStudy_CardPara,
.read_more_icons {
  visibility: visible;
  color: #d0d5dd;
}

.caseStudy_Card:hover {
  background-color: #1d2939;
  -webkit-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.caseStudy_Card:hover .read_more_icons {
  visibility: visible;
}

.caseStudy_Card_Mobile {
  background-color: #1d2939;
  -webkit-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.caseStudy_Card_Content_Mobile {
  visibility: visible;
  color: white;
}
.read_more_icons {
  visibility: hidden;
}

.Service_card_Container {
  overflow-x: hidden;
  border: 2px solid transparent;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

.Service_card_Container:hover {
  transform: scale(1.04);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 2px 38px -10px rgba(233, 234, 237, 0.18);
  /* box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18), */
  border: "2px solid transparent";
}
.heading_class {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.content_class {
  /* height:50px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.mobile-button {
  text-decoration: none;
  text-transform: none;
  color: rgb(71, 84, 103);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.mobile-button:hover {
  background-color: "#F2F4F7";
  color: #026aa2;
  cursor: pointer;
}

.bgImage {
  background: url("../images/Contact\ us.webp");
  background-size: cover;
  border-radius: 1rem;
  background-position: center center;
  background-repeat: no-repeat;
}

.background-slant {
  position: absolute;
  left: -34.62%;
  right: -32.04%;
  top: 80.41%;
  bottom: -88.61%;

  /* Primary/50 */

  background: #f0f9ff;
  backdrop-filter: blur(22.9553px);
  /* Note: backdrop-filter has minimal browser support */
}
.text-clamp-mobile {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
